import React, { useState, useEffect } from 'react';
import PaymentSuccessModal from "./PaymentSuccessModal";

const TransactionStatus = ({ transactionId, token }) => {
  const [status, setStatus] = useState(null);  // Store transaction status
  const [error, setError] = useState(null);    // Store errors (if any)
  const [isRetrying, setIsRetrying] = useState(false);  // Track if a retry attempt is ongoing
  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);

  const checkTransactionStatus = async (transactionId, token, retryInterval = 1000, maxRetries = 50) => {
    let retryCount = 0;


    const retry = async () => {
      if (retryCount >= maxRetries) {
        console.log('Maximum retries reached.');
        setIsRetrying(false);
        return;
      }

      try {
        const response = await fetch('https://muymuymeh.com/transaction/validate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // pass the token for authentication
          },
          body: JSON.stringify({ transactionId }),  // Sending transactionId in the request body
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Transaction Status:', data.status);
        setStatus(data.status);


        // Handle the different transaction statuses
        if (data.status === 'pending') {
          console.log('Transaction is still pending. Retrying...');
          retryCount++;
          setTimeout(retry, retryInterval);  // Retry after the interval
        } else {
          setIsRetrying(false);
          if (data.status === 'success') {
            console.log('Transaction successful!');
            setModalSuccessOpen(true);
          } else if (data.status === 'failure') {
            console.log('Transaction failed!');
          } else {
            console.log('Unknown transaction status:', data.status);
          }
        }
      } catch (error) {
        console.error('Error checking transaction status:', error);
        setError(error.message); // Store the error message
        setIsRetrying(false);
      }
    };

    retry();  // Start the first retry attempt
  };

  const handleOpenModal = () => {
    setModalSuccessOpen(true);
  };

  const handleCloseModal = () => {
    const os = detectMobileOS();

    if (os === 'iOS') {
      if (window.webkit?.messageHandlers?.closeButton) {
        window.webkit.messageHandlers.closeButton.postMessage({
          action: 'closeButton'
        });
      }
    } else if (os === 'Android') {
      window.AndroidInterface.closeButton();
    }
  };

  useEffect(() => {
    if (transactionId) {
      setStatus(null);  // Reset status when transactionId changes
      setError(null);    // Reset error message when transactionId changes
      setIsRetrying(true);  // Indicate retry process is starting
      checkTransactionStatus(transactionId, token);  // Trigger the status check
    }
  }, [transactionId]);  // Run effect only when transactionId changes

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'unknown';
  };

  return (
    <div>
      <h2>Transaction Status</h2>
      {status && <p>Status: {status}</p>}
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {status === 'pending' && isRetrying && (
        <p>Transaction is still pending. Retrying...</p>
      )}
      {status === 'success' && <p>Transaction was successful!</p>}
      {status === 'failure' && <p>Transaction failed.</p>}

      {isModalSuccessOpen && <PaymentSuccessModal onClose={handleCloseModal} />}
    </div>
  );
};

export default TransactionStatus;
