import React, { useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import TransactionStatus from './TransactionStatus';
import LeaveServiceModal from './LeaveServiceModal';

const CheckoutPage = () => {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [isCheckStatusView, setCheckStatusView] = useState(false);
  const [token, setToken] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleCheckoutClick = async () => {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const loginResponse = await fetch('https://muymuymeh.com/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: 'user', password: 'password' }),
        });

        if (!loginResponse.ok) {
          throw new Error('Login failed');
        }

        const loginData = await loginResponse.json();
        const { token } = loginData;

        const transactionResponse = await fetch('https://muymuymeh.com/transaction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            amount: 100,
            currency: 'USD',
            paymentMethod: 'credit_card',
            description: 'Test payment',
          }),
        });

        if (!transactionResponse.ok) {
          throw new Error('Transaction failed');
        }

        const transactionData = await transactionResponse.json();
        console.log('Transaction created:', transactionData);
        setTransactionId(transactionData.transactionId);
        setToken(token);
        setCheckStatusView(true);

        const os = detectMobileOS();

        if (os === 'iOS') {
          if (window.webkit?.messageHandlers?.consoleLogger) {
            window.webkit.messageHandlers.consoleLogger.postMessage({
              action: 'consoleLogger',
              transactionData: transactionData,
            });
          }
        }

        if (os === 'iOS') {
          if (window.webkit?.messageHandlers?.startPayment) {
            window.webkit.messageHandlers.startPayment.postMessage({
              action: 'startPayment',
              transactionData: transactionData.transactionId,
            });
          }
        } else if (os === 'Android') {
          window.AndroidInterface?.startPayment?.(transactionData.transactionId);
        } else {
          alert('Unsupported platform');
        }
      } catch (error) {
        console.error('Error:', error);
        setPaymentStatus('error');
        alert('Something went wrong. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, 1000);
  };

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'unknown';
  };

  window.paymentSuccess = () => {
    setIsLoading(false);
    setPaymentStatus(true);
  };

  useEffect(() => {
    window.toggleModal = (state) => {
      setShowModal(state);
    };
  }, []);

  return (
    <div style={styles.container}>
      { showModal && <LeaveServiceModal /> }
      {/* Show TransactionStatus if visible */}
      {isCheckStatusView ? (
        <TransactionStatus
          transactionId={transactionId}
          token={token}
          onClose={() => setCheckStatusView(false)} // Handle closing the TransactionStatus
        />
      ) : (
        // Show CheckoutPage content only if TransactionStatus is not visible
        <>
          <h1 style={styles.heading}>Checkout Page</h1>
          <button style={styles.button} onClick={handleCheckoutClick}>
            Checkout with Native App
          </button>
          {isLoading && (
            <ReactLoading type="spin" color="#28a745" height={50} width={50} />
          )}
          {paymentStatus === true && (
            <p style={styles.successMessage}>Payment successful!</p>
          )}
          {paymentStatus === 'error' && (
            <p style={styles.errorMessage}>
              Payment failed or unsupported platform!
            </p>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    margin: 0,
    backgroundColor: '#f0f0f0',
    position: 'relative',
  },
  heading: {
    fontSize: '2.5rem',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '15px 30px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s ease',
  },
  successMessage: {
    color: 'green',
    marginTop: '20px',
    fontSize: '1.2rem',
  },
  errorMessage: {
    color: 'red',
    marginTop: '20px',
    fontSize: '1.2rem',
  },
};

export default CheckoutPage;
