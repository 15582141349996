import React from "react";
import "./PaymentSuccessModal.css"; // Import your CSS for styling

const PaymentSuccessModal = ({ onClose }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-header">
          <img
            src="ic_bookMeBus.png"
            alt="Success Icon"
            className="success-icon"
          />
          <h2>Payment Success</h2>
        </div>
        <div className="modal-body">
          <div className="trip-details">
            <h3>Phnom Penh to Siem Reap</h3>
            <p>26 March 2024</p>
          </div>
          <div className="trip-info">
            <div className="info-row">
              <span>Start</span>
              <span>07:00 AM</span>
            </div>
            <div className="info-row">
              <span>Arrive</span>
              <span>11:15 PM</span>
            </div>
            <div className="info-row">
              <span>Price</span>
              <span>$17.00</span>
            </div>
            <hr />
            <div className="info-row">
              <span>Bus</span>
              <span>Virak Buntham Express</span>
            </div>
            <div className="info-row">
              <span>Name</span>
              <span>Jessica Jane</span>
            </div>
            <div className="info-row">
              <span>Phone</span>
              <span>+855 88 876 456</span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
