import logo from './logo.svg';
import './App.css';
import CheckoutPage from './CheckoutPage';

function App() {
  return (
    <div className="App">
      <CheckoutPage />
    </div>
  );
}

export default App;
