import React, { useState, useEffect } from "react";
import "./Modal.css"; // Optional: Add your styling here

const LeaveServiceModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    window.toggleModal(false);
  }
  const handleLeave = () => {
    console.log("User has chosen to leave the service.");
    const os = detectMobileOS();

    if (os === 'iOS') {
      if (window.webkit?.messageHandlers?.goBack) {
        window.webkit.messageHandlers.goBack.postMessage({
          action: 'goBack'
        });
      }
    } else if (os === 'Android') {
      window.AndroidInterface.goBack();
    }
    setIsOpen(false);
  };
  

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'unknown';
  };

  return (
    <div>
        <div className="modal-overlay">
          <div className="modal">
            <p>Are you sure you want to leave from this service?</p>
            <div className="modal-buttons">
              <button onClick={handleClose} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleLeave} className="leave-button">
                Leave
              </button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default LeaveServiceModal;